const REQUESTER_LINK =
  process.env.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_REQUESTER ||
  "https://requester.padme-analytics.de";

const STATION_REGISTRY_LINK =
  process.env.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_STATION_REGISTRY ||
  "https://station-registry.de";

const DEPOT_LINK =
  process.env.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_DEPOT ||
  "https://depot.padme-analytics.de";

const CREATOR_LINK =
  process.env.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_CREATOR ||
  "https://creator.padme-analytics.de";

const STOREHOUSE_LINK =
  process.env.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_STOREHOUSE ||
  "https://storehouse.padme-analytics.de";

const PLAYGROUND_LINK =
  process.env.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_PLAYGROUND ||
  "https://playground.padme-analytics.de";

const MONITORING_DASHBOARD_LINK =
  process.env.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_MONITORING_DASHBOARD ||
  "https://monitoring.padme-analytics.de";

const SCHEMA_LINK =
  process.env.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_SCHEMA ||
  "https://schema.padme-analytics.de";

const DOCUMENTATION_LINK =
  process.env.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_DOCUMENTATION ||
  "https://docs.padme-analytics.de";

const features = [
  {
    title: "Central Service Dashboard",
    description:
      "Here you can request new Trains and inspect the result of finished Trains.",
    label: "Visit the Train Requester",
    link: REQUESTER_LINK,
    infoLink: "/product-overview#central-service",
  },
  {
    title: (
      <>
        Station
        <br />
        Registry
      </>
    ),
    description:
      "Here you can see all the registered Stations and onboard your own.",
    label: "Visit the Station Registry",
    link: STATION_REGISTRY_LINK,
    infoLink: "",
  },
  {
    title: "Train Depot",
    description: "The train depot allows you to access PHT Trains codes.",
    label: "Visit the Train Depot",
    link: DEPOT_LINK,
    infoLink: "",
  },
  {
    title: "Train Creator",
    description: "The train creator allows you to build PHT Trains.",
    label: "Visit the Train Creator",
    link: CREATOR_LINK,
    infoLink: "/product-overview#train-creation-wizard",
  },
  {
    title: "Train Store",
    description: "The train store views all available PHT Trains.",
    label: "Visit the Train Store",
    link: STOREHOUSE_LINK,
    infoLink: "/product-overview#train-creation-wizard",
  },
  {
    title: "Playground",
    description: "Use the playground to simulate and test your execution.",
    label: "Visit the Playground",
    link: PLAYGROUND_LINK,
    infoLink: "",
  },
  {
    title: "Monitoring Dashboard",
    description:
      "Here you can retrieve metadata about different components of the PHT, e.g., a currently running execution.",
    label: "Visit Monitoring Dashboard",
    link: MONITORING_DASHBOARD_LINK,
    infoLink: "/product-overview#pht-infrastructure",
  },
  {
    title: "Schema",
    description: "Our metadata specification for the infrastructure.",
    label: "Visit the Schema",
    link: SCHEMA_LINK,
    infoLink: "",
  },
  {
    title: "Documentation",
    description: "Find detailed information about our components here.",
    label: "See the documentation",
    link: DOCUMENTATION_LINK,
    infoLink: "",
  },
];

export { features };
